import React, { useEffect, useRef, useState } from 'react'
import { navigate } from 'gatsby'
// Components
import { Seo, Loading, PageHeader } from '../components'
import { Primary as PrimaryButton } from '../components/Buttons'
// Context
import { useAuthContext } from '../context/AuthContext'
import { usePrismic } from '../context/PrismicContext'
// Styles
import { ErrorMsg } from '../styles/LoginPage.styles'
import {
  IframeForm,
  AddCardContainer,
  SaveCheckbox,
} from '../styles/AddCard.styles'
// Types
import {
  convertNexioToQSCard,
  NexioFormError,
  NexioFormValidation,
  NexioTokenSavedResponse,
} from '../types/PaymentsTypes'
// Utils
import environment from '../utils/environment'
import { QService } from '../services/q-services'

const SUBMIT_EVENT = 'submit'
const VALIDATION_EVENT = 'formValidations'
const CARD_SAVED_EVENT = 'cardSaved'
const ERROR_EVENT = 'error'

const AddCardPage = ({ location }) => {
  const [nexioIframeUrl, setIframeUrl] = useState('')
  const [saveToFile, setSaveToFile] = useState(true)
  const [isFormClean, setIsFormClean] = useState(true)
  const [isLoading, setIsLoading] = useState(false)
  const [isSaving, setIsSaving] = useState(false)
  const [error, setError] = useState('')
  const { isAuthenticated, qUser, addCardToFile } = useAuthContext()
  const nexioIframe = useRef(null)

  const {
    prismicData: {
      prismicAddCardPage: {
        form_invalid_error,
        loading: loadingText,
        payment_information,
        save_card_for_future_purchases,
        update_payment_info,
        use_card,
      },
    },
  } = usePrismic()

  const returnUrl = location?.state?.returnUrl || '/cart'

  const saveCard = async ({ card, token }: NexioTokenSavedResponse) => {
    setSaveToFile(saveToFile => {
      const qsCard = convertNexioToQSCard(card, token, qUser.associateId)
      addCardToFile(qsCard, saveToFile)
      // navigate(returnUrl)
      navigate(-1)
      return saveToFile
    })
  }

  const handleNexioMessage = ({ origin, data: eventData }) => {
    if (origin === environment.NEXIO_URL) {
      const { event, data: nexioData } = eventData
      switch (event) {
        case CARD_SAVED_EVENT:
          saveCard(nexioData as NexioTokenSavedResponse)
          break
        case SUBMIT_EVENT:
          setIsSaving(true)
          break
        case VALIDATION_EVENT:
          const { isFormValid } = nexioData as NexioFormValidation
          if (!isFormValid) setError(form_invalid_error[0].text)
          else setError('')
          break
        case ERROR_EVENT:
          // handle errors from Nexio form submission
          const { message } = nexioData as NexioFormError
          setIsSaving(false)
          setError(message)
          break
      }
    }
  }

  useEffect(() => {
    if (!isAuthenticated) navigate('/')
    setIsLoading(true)
    const getOneTimeToken = async () => {
      if (nexioIframeUrl) return // used for hot reloading (don't want to spam the sandbox for iframe tokens)
      const token = await QService.Payments.getNexioOneTimeCode()
      const url = `${environment.NEXIO_URL}/pay/v3/saveCard?token=${token}`
      setIframeUrl(url)
    }

    getOneTimeToken()

    window.addEventListener('message', handleNexioMessage)

    return () => window.removeEventListener('message', handleNexioMessage)
  }, [nexioIframeUrl])

  // Use our own button to tell iframe to submit contents
  const submitForm = e => {
    e.preventDefault()
    setIsFormClean(false)
    if (nexioIframe.current.contentWindow) {
      nexioIframe.current.contentWindow.postMessage('posted', nexioIframeUrl) // submit iframe
    }
    return false
  }

  if (isLoading && !nexioIframeUrl)
    return <Loading loading={isLoading} message={loadingText[0].text} />

  return (
    <>
      <Seo title={update_payment_info[0].text} />
      <PageHeader
        exitRoute="/payment-info"
        state={{
          returnUrl,
        }}
      >
        {payment_information[0].text}
      </PageHeader>
      <AddCardContainer>
        <IframeForm
          src={nexioIframeUrl}
          ref={nexioIframe}
          onLoad={() => {
            setIsLoading(false)
          }}
        />
        {!isFormClean && <ErrorMsg>{error}</ErrorMsg>}
        {/* <SaveCheckbox
          name="saveToFile"
          label={save_card_for_future_purchases[0].text}
          checked={saveToFile}
          onChange={() => setSaveToFile(!saveToFile)}
        /> */}
        <PrimaryButton
          style={{
            width: '100%',
          }}
          loading={isSaving}
          onClick={submitForm}
        >
          {' '}
          {use_card[0].text}{' '}
        </PrimaryButton>
      </AddCardContainer>
    </>
  )
}

export default AddCardPage
